.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.button {
  box-shadow: 5px 5px 15px #D1D9E6, -5px -5px 15px #ffffff;
  background: linear-gradient(145deg, #e2e8ec, #ffffff);
}

.layout {
  box-shadow: 5px 5px 15px #D1D9E6, -5px -5px 15px #ffffff;

}

.button:hover {
  cursor: pointer;
  transform: scale(1.05);
}

.button:active {
  transform: scale(0.95);
}

.darkbutton {
  box-shadow: 5px 5px 10px #1a1c28, -5px -5px 10px #12141c;
  background: linear-gradient(to right bottom, #1d1f2c, #12141c)
}

.darklayout {
  box-shadow: 5px 5px 10px #1a1c28, -5px -5px 10px #12141c;
  background: linear-gradient(to right bottom, #202231, #171923)
}

.darkbutton:hover {
  cursor: pointer;
  transform: scale(1.05);
}

.darkbutton:active {
  transform: scale(0.95);
}

.invertImage {
  -webkit-filter: grayscale(1) invert(1);
  filter: grayscale(1) invert(1);
}

@keyframes bounce {

  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-10px);
  }

  60% {
    transform: translateY(-5px);
  }
}

.github {
  animation: bounce 2s infinite;
}

.activeLink>div {
  width: 70px;
  border-radius: 5px;
  transition: all 0.2s ease-in-out;
  background: linear-gradient(145deg, #ff014f, #d11414);
}

.activeLink1>div {
  width: 40px;
  border-radius: 5px;
  transition: all 0.2s ease-in-out;
  background: linear-gradient(145deg, #ff014f, #d11414);
}

.resume {
  box-shadow: 5px 5px 15px #D1D9E6, -5px -5px 15px #ffffff;
  background: linear-gradient(145deg, #e2e8ec, #ffffff);
  cursor: pointer;
}

.image-wrap {
  width: 100%;
  height: 370px;
  overflow: hidden;
}
.image-wrap img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top center;
  transition: all 5s ease-in-out;
}
.image-wrap:hover img {
  object-position: bottom center;
}